var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"term-list-view"},[_c('app-header',{attrs:{"icon":"file-alt","title":"Termos de Uso"}}),_c('section',[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-12 col-md-12 col-sm-6 f-flex text-right"},[_c('b-tooltip',{attrs:{"label":"Novo Cliente"}},[_c('router-link',{attrs:{"to":{
              name: 'terms.save',
            }}},[_c('b-button',{attrs:{"type":"is-primary","icon-right":"plus"}},[_vm._v("NOVO")])],1)],1)],1)]),_c('div',{staticClass:"row mt-2 justify-content-around"},[_c('div',{staticClass:"col-12 col-md-12 col-sm-6"},[_c('b-table',{attrs:{"data":_vm.data,"paginated":_vm.isPaginated,"per-page":_vm.perPage,"current-page":_vm.currentPage,"pagination-simple":_vm.isPaginationSimple,"pagination-position":_vm.paginationPosition,"default-sort-direction":_vm.defaultSortDirection,"pagination-rounded":_vm.isPaginationRounded,"sort-icon":_vm.sortIcon,"sort-icon-size":_vm.sortIconSize,"default-sort":"row.name","total":_vm.total,"loading":_vm.isLoading,"aria-next-label":"Próxima Página","aria-previous-label":"Página Anterior","aria-page-label":"Página","aria-current-label":"Página Atual"},on:{"page-change":_vm.onPageChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([(_vm.rows > 0)?{key:"footer",fn:function(){return [_c('div',{staticClass:"row align-middle text-center"},[(_vm.perPage < _vm.rows)?_c('div',{staticClass:"col-5 col-md-5 col-sm-3 ptext text-right"},[_vm._v(" Exibindo "+_vm._s(_vm.perPage)+" de "+_vm._s(_vm.rows)+" ")]):_vm._e(),(_vm.perPage > _vm.rows)?_c('div',{staticClass:"col-5 col-md-5 col-sm-3 ptext text-right"},[_vm._v(" Exibindo "+_vm._s(_vm.rows)+" de "+_vm._s(_vm.rows)+" ")]):_vm._e(),_c('div',{staticClass:"col-auto col-md-auto col-sm-auto text-center"},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"align":"center","per-page":_vm.perPage,"limit":4},scopedSlots:_vm._u([{key:"page",fn:function(ref){
            var page = ref.page;
            var active = ref.active;
return _c('span',{},[(active)?_c('b',[_vm._v(_vm._s(page))]):_c('i',[_vm._v(_vm._s(page))])])}}],null,false,4221628043),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('div',{staticClass:"col-5 col-md-5 col-sm-3 text-left"},[_c('b-select',{staticClass:"pselect",staticStyle:{"margin-left":"5px"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}},_vm._l((_vm.pageOptions),function(i){return _c('option',{key:i},[_vm._v(_vm._s(i))])}),0)],1)])]},proxy:true}:null],null,true)},[_c('b-table-column',{attrs:{"width":"40%","field":"name","label":"NOME","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}])}),_c('b-table-column',{attrs:{"width":"20%","field":"user_type","label":"TIPO DE TERMO","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.UserTypes.find( function (element) { return element.id == props.row.user_type; } ).name)+" ")]}}])}),_c('b-table-column',{attrs:{"width":"20%","field":"created_at","label":"CADASTRADO EM","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("toDate")(props.row.created_at))+" ")]}}])}),_c('b-table-column',{attrs:{"width":"20%","label":"OPÇÕES","cell-class":"width-100"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                  name: 'terms.save',
                  params: { id: props.row.id },
                }}},[_c('b-tooltip',{staticClass:"m-1",attrs:{"label":"Editar"}},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","icon-right":"edit"}})],1)],1),_c('b-tooltip',{staticClass:"m-1",attrs:{"label":"Deletar"}},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","icon-right":"trash"},on:{"click":function($event){return _vm.onClickDelete(props.row.id)}}})],1)]}}])}),_c('template',{slot:"empty"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"frown","size":"h3"}})],1),_c('p',[_vm._v("Nenhum registro encontrado.")])])])])],2)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }