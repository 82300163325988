<template>
  <div class="term-list-view">
    <app-header icon="file-alt" title="Termos de Uso"></app-header>
    <section>
      <div class="row mt-4">
        <div class="col-12 col-md-12 col-sm-6 f-flex text-right">
          <b-tooltip label="Novo Cliente">
            <router-link
              :to="{
                name: 'terms.save',
              }"
            >
              <b-button type="is-primary" icon-right="plus">NOVO</b-button>
            </router-link>
          </b-tooltip>
        </div>
      </div>
      <div class="row mt-2 justify-content-around">
          <div class="col-12 col-md-12 col-sm-6">
            <b-table
              @page-change="onPageChange"
              :data="data"
              :paginated="isPaginated"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :pagination-position="paginationPosition"
              :default-sort-direction="defaultSortDirection"
              :pagination-rounded="isPaginationRounded"
              :sort-icon="sortIcon"
              :sort-icon-size="sortIconSize"
              default-sort="row.name"
              :total="total"
              :loading="isLoading"
              aria-next-label="Próxima Página"
              aria-previous-label="Página Anterior"
              aria-page-label="Página"
              aria-current-label="Página Atual"
            >
              <b-table-column width="40%" v-slot="props" field="name" label="NOME" sortable>
                {{ props.row.name }}
              </b-table-column>
              <b-table-column
                width="20%"
                v-slot="props"
                field="user_type"
                label="TIPO DE TERMO"
                sortable
              >
                {{
                  UserTypes.find(
                    (element) => element.id == props.row.user_type
                  ).name
                }}
              </b-table-column>
              <b-table-column
                width="20%"
                v-slot="props"
                field="created_at"
                label="CADASTRADO EM"
                sortable
              >
                {{ props.row.created_at | toDate }}
              </b-table-column>
              <b-table-column
                width="20%"
                v-slot="props"
                label="OPÇÕES"
                cell-class="width-100"
              >
                <router-link
                  :to="{
                    name: 'terms.save',
                    params: { id: props.row.id },
                  }"
                >
                  <b-tooltip label="Editar" class="m-1">
                    <b-button
                      type="is-primary"
                      size="is-small"
                      icon-right="edit"
                    />
                  </b-tooltip>
                </router-link>
                <b-tooltip label="Deletar" class="m-1">
                  <b-button
                    @click="onClickDelete(props.row.id)"
                    type="is-primary"
                    size="is-small"
                    icon-right="trash"
                  />
                </b-tooltip>
              </b-table-column>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="frown" size="h3"></b-icon>
                    </p>
                    <p>Nenhum registro encontrado.</p>
                  </div>
                </section>
              </template>
              <template #footer v-if="rows > 0">
                <div class="row align-middle text-center">
                  <div v-if="perPage < rows" class="col-5 col-md-5 col-sm-3 ptext text-right">
                    Exibindo {{ perPage }} de {{ rows }}
                  </div>
                  <div v-if="perPage > rows" class="col-5 col-md-5 col-sm-3 ptext text-right">
                    Exibindo {{ rows }} de {{ rows }}
                  </div>
                  <div class="col-auto col-md-auto col-sm-auto text-center">
                    <b-pagination v-model="currentPage" :total-rows="rows" align="center" :per-page="perPage" :limit="4">
                      <span slot="page" slot-scope="{ page, active }">
                        <b v-if="active">{{ page }}</b>
                        <i v-else>{{ page }}</i>
                      </span>
                    </b-pagination>
                  </div>
                  <div class="col-5 col-md-5 col-sm-3 text-left">
                    <b-select class="pselect" v-model="perPage" style="margin-left: 5px">
                      <option v-for="i in pageOptions" :key="i">{{i}}</option>
                    </b-select>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
import TermsService from '@/services/terms.service';
import debounce from 'lodash/debounce';

import * as moment from 'moment';

export default {
  data: () => ({
    isLoading: true,
    data: [],
    rows: 0,
    total: 0,
    page: 1,
    terms: [],
    UserTypes: [],
    searchName: '',
    searchTerm: '',
    searchUserType: null,
    perPage: 10,
    isPaginated: false,
    isPaginationSimple: false,
    isPaginationRounded: false,
    paginationPosition: 'both',
    defaultSortDirection: 'asc',
    sortIcon: 'arrow-down',
    sortIconSize: 'is-small',
    currentPage: 1,
    pageOptions: [10, 20, 40, 80],
  }),
  watch: {
    searchUserType() {
      this.page = 1;
      this.load();
    },
    searchName: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
  },
  filters: {
    toDate(value) {
      if (value) {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    },
  },
  methods: {
    load() {
      this.UserTypes = [
        { id: 1, name: 'Advogado' },
        { id: 2, name: 'Cliente' },
        { id: 3, name: 'Secretária' },
      ];

      this.isLoading = true;

      TermsService.get({
        page: this.page,
        name: this.searchName,
        user_type: this.searchUserType != null ? this.searchUserType : '',
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
          this.rows = this.data.length;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Termo',
        message:
          'Tem certeza que deseja <b>excluir</b> este termo? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Termo',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      TermsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Termo excluído com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir o Termo.',
              'error',
              4000
            );
          }
        });
    },
  },
  mounted() {
    this.load();
  },
};
</script>
<style scoped>
.filters {
  align-items: center;
}
.separated-table {
  margin: 5px 0;
}
</style>
